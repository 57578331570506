/* You can add global styles to this file, and also import other style files */
// Uses must be above all
@use "@angular/material" as mat;
@import 'ngx-toastr/toastr';
// Imports
@import url('//fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.ng-select-theme-default {
  @import "~@ng-select/ng-select/themes/default.theme";
}

.ng-select-theme-material {
  @import "~@ng-select/ng-select/themes/material.theme";
}

@import '@angular/material/theming';

@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-balham.css';
/*@ i m p o r t  '~ag-grid-community/dist/styles/ag-theme-luma.css'; */
@import 'variables';
@import 'ag-grid';
@import 'dialogs';
@import 'utility';
@import 'gwim/details-page';
@import 'buttons';
@import 'chips';
@import "animations";
@import 'input';
@import "material-overrides";
@import "shared-marketplace";

@font-face {
  font-family: 'IBMPlexSansLight';
  src: url('../assets/fonts/IBMPlexSans/IBMPlexSans-Light.woff2') format('woff2'),
  url('../assets/fonts/IBMPlexSans/IBMPlexSans-Light.woff') format('woff'),
  url('../assets/fonts/IBMPlexSans/IBMPlexSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'IBMPlexSansRegular';
  src: url('../assets/fonts/IBMPlexSans/IBMPlexSans-Regular.woff2') format('woff2'),
  url('../assets/fonts/IBMPlexSans/IBMPlexSans-Regular.woff') format('woff'),
  url('../assets/fonts/IBMPlexSans/IBMPlexSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'IBMPlexSansMedium';
  src: url('../assets/fonts/IBMPlexSans/IBMPlexSans-Medium.woff2') format('woff2'),
  url('../assets/fonts/IBMPlexSans/IBMPlexSans-Medium.woff') format('woff'),
  url('../assets/fonts/IBMPlexSans/IBMPlexSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'IBMPlexSansSemiBold';
  src: url('../assets/fonts/IBMPlexSans/IBMPlexSans-SemiBold.woff2') format('woff2'),
  url('../assets/fonts/IBMPlexSans/IBMPlexSans-SemiBold.woff') format('woff'),
  url('../assets/fonts/IBMPlexSans/IBMPlexSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'IBMPlexSansBold';
  src: url('../assets/fonts/IBMPlexSans/IBMPlexSans-Bold.woff2') format('woff2'),
  url('../assets/fonts/IBMPlexSans/IBMPlexSans-Bold.woff') format('woff'),
  url('../assets/fonts/IBMPlexSans/IBMPlexSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'LocalFruit45';
  src: url('../assets/fonts/FrutigerForUbs/FrutigerforUBSWeb.woff2')  format('woff2');
  src: url('../assets/fonts/FrutigerForUbs/FrutigerforUBSWeb.woff')  format('woff');
}

@font-face {
  font-family: 'LocalFruitBold';
  src: url('../assets/fonts/FrutigerForUbs/FrutigerforUBSWeb-Bd.woff2')  format('woff2');
  src: url('../assets/fonts/FrutigerForUbs/FrutigerforUBSWeb-Bd.woff')  format('woff');
}

@font-face {
  font-family: 'LocalFruit55Roman';
  src: url('../assets/fonts/FrutigerForUbs/FrutigerforUBSWeb.woff2')  format('woff2');
  src: url('../assets/fonts/FrutigerForUbs/FrutigerforUBSWeb.woff')  format('woff');
}

@font-face {
  font-family: 'LocalFruit45LightB';
  src: url('../assets/fonts/FrutigerForUbs/FrutigerforUBSWeb.woff2')  format('woff2');
  src: url('../assets/fonts/FrutigerForUbs/FrutigerforUBSWeb.woff')  format('woff');
}

@font-face {
  font-family: 'LocalFruit45Italics';
  src: url('../assets/fonts/FrutigerForUbs/FrutigerforUBSWeb-It.woff2')  format('woff2');
  src: url('../assets/fonts/FrutigerForUbs/FrutigerforUBSWeb-It.woff')  format('woff');
}


@font-face {
  font-family: 'LocalFruit45LightItalics';
  src: url('../assets/fonts/FrutigerForUbs/FrutigerforUBSWeb-LtIt.woff2')  format('woff2');
  src: url('../assets/fonts/FrutigerForUbs/FrutigerforUBSWeb-LtIt.woff')  format('woff');
}



$luma-palette-primary-blue: (50: #1a75e0,
  100: #bbdefb,
  200: #1364c3,
  300: #4fc3f7,
  400: #29b6f6,
  500: #03a9f4,
  600: #039be5,
  700: #0288d1,
  800: #0277bd,
  900: #01579b,
  A100: #80d8ff,
  A200: #40c4ff,
  A400: #00b0ff,
  A700: #0091ea,
  contrast: (50: $light-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text));

$luma-palette-accent-blue: (50: #e8f1fb,
  100: #edf4fd,
  200: #bbcfe6,
  300: #4fc3f7,
  400: #29b6f6,
  500: #03a9f4,
  600: #039be5,
  700: #0288d1,


  800: #0277bd,
  900: #01579b,
  A100: #80d8ff,
  A200: #40c4ff,
  A400: #00b0ff,
  A700: #0091ea,
  contrast: (50: #4a90e2,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  ));

$luma-palette-warn-red: (50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #f44336);



// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$custom-typography: mat.define-typography-config($font-family: '"Roboto", sans-serif;',
  $headline: mat.define-typography-level(28px, 32px, 400),
  $button: mat.define-typography-level(14px, 14px, 400));

@include mat.core($custom-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$luma-app-primary: mat.define-palette($luma-palette-primary-blue, 50, 100, 200);
$luma-app-accent: mat.define-palette($luma-palette-accent-blue, 50, 100, 200);

// The warn palette is optional (defaults to red).
$luma-app-warn: mat.define-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$luma-app-theme: mat.define-light-theme($luma-app-primary, $luma-app-accent, $luma-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@include mat.all-component-themes($luma-app-theme);

// Import luma-app layout
@import 'layout';


//// ELEMENTS /////
body {
  font-family: "Roboto", sans-serif;
}

mat-card-footer {
  font-size: 14px;
}

mat-card-title {
  font-size: 1.75em;
  font-weight: bold;
}

mat-card.order-details_mat-card {
  height: auto;
  padding: 8px;
}

.mat-calendar-arrow {
  display: block;
}


mat-radio-button {
  margin-right: 2rem;
}

table {
  width: 100%;
}

th {
  font-weight: bold;
  font-size: 1.05em;
}

//// CLASSES /////
.admin-file-upload {
  margin: 10px;
}

.ag-theme-luma-product-details {
  margin-top: 1rem;
}

.any-chart-component {
  flex-grow: 2;
}

.backdrop {
  background: $luma-brand-bright-green;
}

.backgroundWhite {
  background-color: white;
}

.breadcrumb-container__breadcrumb-landing {
  font-weight: 400;
}

.cal-month-panel {
  margin-top: 12px;
}

.call-proximity-cell-container.amber {
  color: $luma-alert-orange;
}

.call-proximity-cell-container.green {
  color: $luma-alert-green;
}

.call-proximity-cell-container.red {
  color: $luma-alert-red;
}

.call-proximity-cell__days-until-label {
  color: $luma-brand-gray;
}

.call-proximity-cell__days-until-label.amber {
  color: $luma-alert-orange;
}

.call-proximity-cell__days-until-label.green {
  color: $luma-alert-green;
}

.call-proximity-cell__days-until-label.red {
  color: $luma-alert-red;
}

.call-proximity-cell__message-for-date {
  color: $luma-brand-gray;
}

.call-proximity-cell__value.amber {
  color: $luma-alert-orange;
}

.call-proximity-cell__value.green {
  color: $luma-alert-green;
}

.call-proximity-cell__value.red {
  color: $luma-alert-red;
}

.chart-container .mat-radio-button.mat-accent .mat-radio-inner-circle,
.chart-container .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.chart-container .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.chart-container .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  color: $luma-brand-green;
}

.chart-type-filters__chart-type-option {
  color: $luma-link;
  font-size: 24px;
}

.chart-type-select {
  width: 200px;
  height: auto;
  line-height: 2rem;
  font-size: 1.2rem;
  border: 2px solid $luma-medium-gray;
  background-color: $luma-brand-green;
}

.card-content-container {
  border-top-style: solid;
  border-top-width: 4px;
  border-top-color: $luma-sys-slateblue;
  height: auto;
  min-height: 34em;
}

.card-content-container__high-charts {
  border-top-style: solid;
  border-top-width: 4px;
  border-top-color: $luma-sys-slateblue;
  height: auto;
}

.calendar-container {
  border-top-style: solid;
  border-top-width: 4px;
  border-top-color: $luma-sys-slateblue;
}

.calendar-event-list {
  overflow: scroll;
}

.core-detail-key {
  font-weight: 800;
}

.core-detail-value {
  font-weight: 400;
  font-style: italic;
}


.disclaimer-brokers-only {
  color: $luma-alert-red;
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  letter-spacing: normal;
}

.documentation-panel .mat-dialog-container {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  border: solid 1px #cfd2d5;
  background-color: #ffffff;
  font-style: normal;
  font-stretch: normal;
}

.documentation-dialog-header {
  font-size: 13px;
  font-weight: bold;
  color: #222222;
  border-radius: 3px;
  border: solid 1px #cfd2d5;
  background-color: #f4f6f8;
}

.documentation-dialog-content {
  font-size: 13px;
  font-weight: normal;
}

.document-link {
  display: block;
  float: left;
  height: auto;
  margin: 0;
  width: 180px;

}

.document-link:hover {
  cursor: pointer;
}

.document-link-icon {
  width: 12px;
  height: 15px;
  color: $luma-brand-skyblue;
}

.document-dialog-overlay {
  background-color: transparent;
}

.evenrow {
  background-color: $luma-even-table-gray;
}

.provided-value-cell-container.amber {
  color: $luma-alert-orange;
}

.provided-value-cell-container.green {
  color: $luma-alert-green;
}

.provided-value-cell-container.red {
  color: $luma-alert-red;
}

.provided-value-cell__message-for-date {
  color: $luma-brand-gray;
}

.environment-notification {
  margin-top: -19px;
  height: 40px;
  background-image: linear-gradient(to bottom, #f9cc22, #f5a623);
}

.environment-notification-text {
  text-align: center;
  font-weight: 300;
  padding-top: 10px;
}

.environment-notification-text-icon {
  margin-right: 15px
}


.example-data-disclaimer {
  color: #0e1f2c;
  font-weight: lighter;
  font-style: italic;
}

.example-perfomance-metrics__primary-header {
  height: 32px;
  font-size: 28px;
  font-weight: normal;
  font-style: normal;
  color: #0e1f2c;
}

.excel-icon {
  font-size: inherit;
}

.excel-icon-button-spacing {
  margin-bottom: 20px;
}

.excel-icon:disabled {
  color: $luma-light-gray;
  pointer-events: none;
}

.external-filter-clear-type {
  background-color: $luma-alert-red;
  color: #ffffff;
  line-height: inherit !important;
}

app-lifecycle-manager-overview {
  .external-filter-group-container {
    height: 55em;
  }
}

.external-filter-group-container {
  border-top-style: solid;
  border-top-width: 4px;
  border-top-color: $luma-sys-slateblue;
  height: 34em;
}

.external-filter-toggle-option.secondary {
  background-color: #1a75e0;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  min-width: 8rem;
  min-height: 3rem;
}

.external-filter-clear-option.secondary {
  background-color: $luma-alert-red;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  min-width: 1rem;
  min-height: .5rem;
}

.external-filter-toggle-option.secondary[disabled] {
  color: #1a75e0;
  background-color: #d6d8da;
  border: 2px solid #1a75e0;
}

.functions-as-icons {
  color: $luma-link;
}

.functions-as-icons:hover {
  cursor: pointer;
}

.functions-as-icons.disabled {
  color: $luma-light-gray;
}

.functions-as-icons.disabled:hover {
  cursor: default;
}

.green-arrow {
  color: $luma-green;
  font-weight: bolder;
  margin: 1rem;
}


button.grid-icon-button:focus {outline:0;}
.grid-icon-button {
  padding: 10px;
  font-size: 22px;
  background-color: white;

  border: none;
  color: $luma-link;
  cursor: pointer;
  &:disabled {
      color: $luma-light-gray;
      pointer-events: none;
      cursor: default;
  }
}

.grid-data-form {
  float: right;
  margin-right: 10px;
  margin-top: 0px;
  font-size: 14px
}

.horizontalBarIcon svg {
  transform: rotateX(180deg) rotate(90deg);
}

.heavy-font {
  font-weight: 800;
}

.info-nugget {
  min-width: 84px;
  height: auto;
  border-right: 1px solid #808080;
}

.info-nugget:last-child {
  border-right: 0;
}

.info-nugget-container {
  font-size: 1.2rem;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #0e1f2c;
  margin-bottom: 1.4rem;
  margin-top: 1.4rem;
}

.info-nugget-label {
  font-size: 0.8rem;
}

.large-text {
  font-size: 22px;
}

.luma-bright-green {
  background-color: $luma-brand-green;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: $luma-grid-header-gray;
  min-width: 1rem;
  min-height: .5rem;
}

.luma-global-product-search-cta-button {
  background-color: $luma-link;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: $pure-white;
  min-width: 1rem;
  min-height: .5rem;
}

.luma-link-green {
  color: $luma-brand-green;
}

.luma-link-red {
  color: $luma-alert-red;
}

.luma-link-red:visited {
  color: $luma-alert-red;
}

.luma-link-blue {
  color: $luma-brand-skyblue;
  text-decoration: none;
}

.marketplace-grid {
  margin-left: 8px;
  margin-top: 1.0rem;
  margin-right: 8px;
}

.marketplace-documentation-panel {
  font-size: 13px;
  font-weight: bold;
  color: #222222;
  & .mat-dialog-container {
    padding: 0;
  }
  & .tooltip-header {
    padding: 1rem;
    margin: 0;
    border-radius: 3px;
    border: solid 1px #cfd2d5;
    background-color: #f4f6f8;
  }
  & .document-link-list {
    display: flex;
    flex-direction: row;
    margin: .5rem;
    cursor: pointer;

    & .luma-link-blue {
      margin: .2rem .5rem 0 1rem;
    }
  }
  & .tooltip-document-row {
    min-width: 11rem;
  }
  & .no-documents {
    width: 11rem;
    margin: 0 1rem;
    text-align: center;
  }
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}

.mat-drawer-container {
  background-color: #f4f6f8;
}

.mat-form-field-wrapper {
  margin-bottom: -11px !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-accent .mat-radio-inner-circle {
  //border-radius: 7.5px;
  background-color: #1A75E0;
  border-color: #4a5660;
}

.mat-toolbar-single-row.header-toolbar {
  height: 54px;
}

.mat-tooltip {
  font-size: 14px;
}

.mat-tab-label-active {
  background-color: $luma-brand-bright-green;
}

.gray-mat-tabs {
  $luma-tab-primary-darkened: darken($luma-tab-primary, 65%);
  $luma-tab-primary-lightened: lighten($luma-tab-primary, 65%);

  & .mat-tab-labels > .mat-tab-label {
    background-color: $pure-white;
    border-radius: 3px 3px 0 0;
    height: 3rem !important;
    min-width: 8.2em !important;
    user-select: none;

    margin-left: 1em;
    margin-top: .5em;

    border-top:    2px solid $luma-tab-primary;
    border-right:  2px solid $luma-tab-primary;
    border-left:   2px solid $luma-tab-primary;

    color: #4B5761;

    &:first-child {
      margin-left: 0.75em;
    }

    &.mat-tab-disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &:hover:not(.mat-tab-label-active) {

      background-color: $luma-tab-primary-lightened;
      color: $luma-tab-primary-darkened;

      border-top:    2px solid $luma-tab-primary-darkened;
      border-right:  2px solid $luma-tab-primary-darkened;
      border-left:   2px solid $luma-tab-primary-darkened;
    }

    &.mat-tab-label-active {
      &:not(.mat-tab-disabled) {
        opacity: 1;
      }

      background-color: $luma-tab-primary;
      color: $pure-white;

      &:focus, &[focus] {
        background-color: $luma-tab-primary;
      }
    }
  }

  & .mat-tab-header {
    border-bottom: 0.25em solid $luma-tab-primary;
  }

  & .mat-ink-bar {
    display: none;
  }

  &-large {
    @extend .gray-mat-tabs;
    & .mat-tab-labels > .mat-tab-label {
      font-size: 1.25em;
      margin-left: 1.25em;
      &:first-child {
        margin-left: 1em;
      }
    }
  }

  &-config-charts {
    @extend .gray-mat-tabs;

    & .mat-tab-labels > .mat-tab-label:first-child {
      margin-left: 0;
    }

    .mat-tab-body-wrapper {
      height: 26em;

      .mat-tab-body-content {
        overflow: hidden;
      }

      & .config-chart {
        margin: 1em 0.5em;
        height: 24em;
      }
    }

  }
  &-medium {
    @extend .gray-mat-tabs;
    & .mat-tab-labels > .mat-tab-label {
      font-size: 1em;
    }

  }
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $luma-brand-gray;
}

.mat-tab-header {
  border-bottom: 0;
}

.mat-tab-label {
  border-radius: 0.46rem 0.46rem 0 0;
  height: 2rem !important;
  min-width: 130px !important;
}
.marketplace-settings__main-content-container .mat-tab-label {
  height: 3rem !important;
}

.mat-tab-label-content {
  font-weight: 600;
}


.mat-flat-button:hover svg path {
  fill: $luma-brand-green;
}

.no-data-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.no-documents {
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #888888;
}

.table-labels-md {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #888888;
}


.ok-button {
  background-color: #1a75e0;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  min-width: 8rem;
  min-height: 44px;
}

.portfolio-card {
  height: 100%;
}

.portfolio-container {
  height: auto;
  min-height: 444px;
}

.product-details-link {
  text-decoration: none;
}

.product-details-link:hover {
  cursor: pointer;
}

.product-details-metrics {
  background: #fff;
}

.product-search-button {
  float: left;
  margin-left: 1rem !important;
  margin-right: 0 !important;
  margin-top: 11px !important;
}

.product-search-field {
  float: left;
  width: 130px;

  ::ng-deep .mat-form-field-infix {
    border: solid lightgray 1px;
    padding-left: 10px;
  }
}

.product-search-form {
  float: right;
  margin-right: 0px;
  margin-top: 0px;
  font-size: 14px
}

.product-search-results-table-header {
  background-color: #495660;
  border-bottom: 1px solid #BDC3C7;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  font-family: Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  padding: 4px;
}

.product-search-results-table-cell {
  border: 1px solid #BDC3C7;
  border-top: 0;
  padding: 4px;
  text-align: center;
}

.product-search-results-table-cell.cell-documentation {
  text-align: left;
}

.product-details-container {
  border-top-style: solid;
  border-top-width: 4px;
  border-top-color: $luma-sys-slateblue;
}

.product-details-document-icon {
  width: 41px;
  height: 51px;
  color: $luma-brand-skyblue;
}

.product-details-document-icon-no-documents {
  width: 41px;
  height: 51px;
  color: $luma-brand-gray;
}

.product-document-icon-active {
  width: 41px;
  height: 51px;
  color: $luma-link;
}

.product-details-document-link {
  display: block;
  float: left;
  height: auto;
  width: 100%;
  margin: 0.8rem;
}

.product-details-document-link:hover {
  cursor: pointer;
}

.product-details-tab-container {
  background: #fff;
  max-width: 100%;
  padding: 2rem;
}

.proximity-green{
  background-color: $luma-status-green;
}

.proximity-orange {
  background-color: $luma-status-orange;
}

.proximity-red{
  background-color: $luma-status-red;
}

.quick-filter-clear-search {
  background-color: $luma-alert-red;
  color: #ffffff;
}

.select-box-options {
  min-width: 7rem;
  min-height: 2rem;
  font-size: 24px;
}

.special-gray-line {
  background-color: $luma-light-gray;
}

.static-graph-image {
  max-width: 90%;
  max-height: 23rem;
  height: auto;
}

.summary-header {
  background: $luma-brand-green;
  color: #00263E;
  font-size: 1.2rem;
  font-weight: 600;
}

.table-container {
  border-top-style: solid;
  border-top-width: 4px;
  border-top-color: #4A5660;
}

.typeahead-form {
  float: right;
  margin-right: 10px;
  margin-top: 0px;
  font-size: 14px
}

.typeahead-field {
  width: 130px;

  ::ng-deep .mat-form-field-infix {
    border: solid lightgray 1px;
    padding-left: 10px;
  }
}

.warning-modal-content-as-full-page {
  border-top: 1px solid $luma-light-gray;
}

.warning-yellow {
  color: $luma-brand-orange;
  //background-color: $luma-brand-yellow;
}

/// IDs ///
// #ag-grid {
//   min-height: 500px;
// }

#pendingWarningContainer, .warning-container {
  padding-top: 16px;
  text-align: center;
  vertical-align: center;

  mat-progress-spinner {
    display: inline-block;
  }

  .exclamation-circle {
    color: $alert-red;
    font-size: 5em;

    &-info {
      color: $luma-link;
      font-size: 5em;
    }
  }
}

.warning-container {
  width: 100%;
}

@media only screen and (max-width: 1280px) {
  .menu-card {
    .mat-card-content {
      display: none;
    }
  }
}

/// ::ng-deep ///
::ng-deep .mat-option-text {
  font-size: 14px;
}

.pdf-icon-bubble .fa-layers-counter {
   -webkit-transform: scale(0.45);
    transform: scale(0.45);

    top: -5px;
    right: -7px;
}


// -------- GWIM ----------

.cal-month-panel {
  margin-top: 12px;
}

// TO-DO move to correct place
.grid-ellipsis-btn {
  min-width: 32px !important;
  border: 1px solid rgba(26, 117, 224, .5) !important;;
  padding: 0 8px !important;
}

.w-100 {
  width: 100%;
}

// TO-DO fix alternate pattern 
// .details-form .col > *:nth-child(odd) .row{
//   background: #f9f9fa;
// }

// TO-DO fix alternate pattern 
// .documents-form .col > *:nth-child(odd) > .row{
//   background: #f9f9fa;
// }

// TO-DO fix alternate pattern 
// .dates-form .col > *:nth-child(odd) > .row{
//   background: #f9f9fa;
// }

// .dates-container {
//   background: #fff;
//   font-size: 14px;
//   padding: 8px;

//   .col {
//     width: 100%;

//     .row {
//       min-height: 56px;
//       height: auto;
//       justify-content: flex-start;

//       .label {
//         width: 28%;
//       }

//       .value {
//         letter-spacing: .4px;

//         &.pl-1 {
//           padding-left: 1px;
//         }
//       }
//     }
//   }
// }

.luma-link-blue {
  color: $luma-brand-skyblue;
  text-decoration: none;
}

.bulk-order-upload-modal {
  .mat-dialog-content {
    max-height: 100%;
  }
  width: 800px;
  max-height: 80vh;
}


.login-page-container-wrapper {
  text-align: center;
  margin-top: 5em;
}

.login-page-container {
  display: inline-block;
  border-radius: 4px;
  border: 0.1em solid $luma-brand-gray;
  padding: 1em;
  width: 24em;
  text-align: center;

  mat-form-field {
    margin-left: 1em;
    width: 22em;
    display: block;
  }

  .login-button {
    margin-top: 1em;
  }

  .error {
    color: $alert-red;
  }
}

.header-fill-space {
    flex: 1 1 auto;
  }

  .fpo-header {
    width: 100%;
    height: 6px;
    background-image: linear-gradient(to right, #f9cc22, #c6da7c);
  }

  .mat-toolbar {
    background-color: white;
  }


  .header-toolbar {
    display: flex;
    justify-content: space-between;
  }

  .nav {
    font-family: 'Roboto';
    padding-left: 5px;
    border: 1px solid $luma-medium-gray;
    color: $luma-nav-navblue;
    background-color: transparent;
    min-width: 180px;
    min-height: 36px;
    margin-right: 24px;
  }

  .nav:hover {
    cursor: pointer;
    background-color: $luma-light-gray;
  }


  .navigatoin-button-container{
    flex-grow: 3;
    display: flex;
    margin-left: 1.366rem;
  }

  .marketplace-settings__main-content-container .mat-tab-list .mat-tab-labels .mat-tab-label-active {
    background-color: transparent;
    opacity: 1;
  }

  .marketplace-settings__main-content-container .mat-ink-bar {
    background-color: black !important;
    height: 3px !important;
  }

  app-product-center-dashboard {
    .mat-tab-label.mat-tab-label-active {
      background: none !important;
    }
    .mat-tab-label-active {
      font-weight: 600 !important;
      color:black !important;
      opacity: 1;
    }
    .mat-ink-bar {
      background-color: $luma-link !important;
    }
    .mat-tab-labels {
        margin-bottom: 0.5rem;
    }
    .mat-tab-list {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
      margin-bottom: 1rem;
    }
  }

  .luma-material-button {
    height: 44px;
  }

  .full-height-and-width {
    height: 100%;
    width: 100%
  }

  .mat-drawer-inner-container {
    overflow-x: hidden !important;
  }


.wrap {
  white-space: pre-wrap;      /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap;     /* Opera <7 */
  white-space: -o-pre-wrap;   /* Opera 7 */
  word-wrap: break-word;      /* IE */
}

.settings-inner-tabs {
  & .mat-tab-labels > .mat-tab-label {
    font-size: 1rem;
    background-color: transparent !important;
    color: $pure-black !important;
    margin-left: 0px !important;
    margin-top: 0px !important;
    border: none;
    color: rgba(0,0,0,.87);

    &:hover:not(.mat-tab-label-active) {
      border: none;
    }
  }

  & .mat-tab-header {
    border-bottom: 1px solid rgba(0,0,0,.12);
  }

  & .mat-ink-bar {
    display: block;
    background-color: #1a75e0 !important;
  }
}


app-number-input-cell {
  .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
    margin-right: 1rem;
  }
}

#hidden-range-input {
  height: 0;
  width: 0;
  & .mat-date-range-input-container {
    display: none;
  }
}

.font-italic {
  font-style: italic;
}

.lifecycle-confirmation-panel {
  width: 460px;

  .mat-dialog-actions {
    place-content: unset;
    justify-content: flex-end;
  }
}

.forwared-email-modal .mat-dialog-container {
  border-radius: 22px !important;
  background-color: #f4f6f8;
  padding-left: 5px;
  overflow: hidden;
}

.simple-tooltip-panel .mat-dialog-container {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  border: solid 1px #cfd2d5;
  background-color: #ffffff;
  font-style: normal;
  font-stretch: normal;

  margin: 0px;
  padding: 0px;
  max-width: 500px;
  overflow: hidden;
  display: flex;

  app-simple-tooltip-dialog {
    margin: 10px;
  }
}

app-lifecycle-manager-overview {
  .ag-grid-container {
    margin-left: 0;
    margin-right: 0;
  }
}

@mixin custom-select($name, $background, $color) {
  $class-name: mat-select-wrapper-#{$name};
  @at-root  .#{$class-name} {
    ::-webkit-scrollbar {
      width: 0.375rem;
      overflow-y: scroll;
      background: grey;
      box-shadow: inset 0 0 4px #707070;
    }

    ::-webkit-scrollbar-thumb {
      background: $color;
      border-radius: 10px;
    }

    &-no-label {
      @extend .#{$class-name};
      .mat-form-field-infix {
        border-top: none;
      }
    }

    .mat-form-field.mat-focused.mat-primary .mat-select-arrow,
    .mat-select-arrow,
    .mat-select-value,
    .mat-form-field-label,
    .mat-option-text {
      color: $color;
    }

    .mat-form-field-appearance-legacy  {
      & .mat-focused, .mat-form-field-label {
        margin-left: 0.5rem;
        padding-top: 0.25rem;
        color: $color;
      }

      & .mat-form-field-infix {
        border-radius: 4px;
        padding: 0.5rem;
        background: $background;
      }
    }
  }
}


@include custom-select('blue', $luma-link, $pure-white);

.flex-1 {
  flex:1;
}

//app-product-data-master-detail-renderer {
//  .mat-card:not([class*='#{$_mat-elevation-prefix}']) {
//    box-shadow: none;
//  }
//} TODO: figure out how to specify this in a better way

app-sector-editor {
  .mat-form-field-infix {
    display: flex;
  }
}

app-update-values-drawer {
  .mat-form-field {
    font-size: 12px;

    .mat-form-field-wrapper {
      margin-bottom: -1.25em !important;
    }

    .mat-form-field-label-wrapper {
      height: 2rem;
      top: -1.1rem;
    }

    .mat-form-field-flex {
      width: 4rem;
    }

    .mat-form-field-flex > .mat-form-field-infix {
      padding: 0.4em 0px !important;
    }

    .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
      transform: translateY(-1.1em) scale(.75);
      width: 133.33333%;
    }
  }
}

app-confirm-or-cancel-modal {
  .confirm-or-cancel-dialog-content {
    text-align: center;
    &.mat-dialog-content {
      overflow-x: hidden;
    }
  }
}

.luma-ng-slider-template {
  &.ngx-slider {
    transition: none !important;
    & .ngx-slider-bar-wrapper {
      transition: none !important;
    }
    & .ngx-slider-span.ngx-slider-bubble {
      transition: none;
      font-size: 1.1em;
    }

    & .ngx-slider-bar {
      height: .9em;
      top: 13px;
    }
    & .ngx-slider-selection {
      background-color: $info-blue;
    }

    & .ngx-slider-pointer {
      background-color: $luma-primary-v2;
      width: 1.5em;
      height: 1.5em;
      top: -0.6em;
      transition: none !important;
      border-radius: 4px;
      // hide some weird element added in ngx-slider
      &:after {
        display: none;
      }
      // todo: fancy stuff on hover/active
      &:focus {
        font-size: 1.1em;
        outline: none;
      }
    }
  }
}

.luma-range-slider {
  @extend .luma-ng-slider-template;
  &.ngx-slider {
    .ngx-slider-span.ngx-slider-bubble.ngx-slider-limit {
      display: none;
    }
  }
}

.luma-single-range-slider {
  @extend .luma-ng-slider-template;
  &.ngx-slider {
    & .ngx-slider-span.ngx-slider-bubble.ngx-slider-model-value {
      display: none;
    }
  }
}

.custom-mat-form {
  .field-name {
    flex: 1;
    align-self: center;
  }

  .field-form {
    flex: 1;
  }

  .mat-form-field {
    font-size: 12px;

    .mat-form-field-wrapper {
      margin-bottom: -1.25em !important;
    }

    .mat-form-field-label-wrapper {
      height: 2rem;
      top: -1.1rem;
    }

    .mat-form-field-flex {
      width: 8rem;
    }

    .mat-form-field-flex > .mat-form-field-infix {
      padding: 0.4em 0px !important;
    }

    .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
      transform: translateY(-1.1em) scale(.75);
      width: 133.33333%;
    }
  }
}

$custom-mat-width:22rem;
$double-custom-mat-width:44rem; // should be *2 one above

app-custom-mat-input {
  @extend .custom-mat-form;
  .input {
    display: flex;
    flex: 1;
    width: $custom-mat-width;
    padding-left: 2rem;
  }
}

.show-ranges-structure {
  .show-ranges-container {
    display: flex;
    flex: 1;
    width: $custom-mat-width;
    padding-left: 2rem;
    padding-bottom: 1rem;
  }

  .mat-form-field {
    padding-left: .5rem;
  }

  .show-ranges {
    width: 4rem;
    height: 36px;
  }

  .button-container {
    width: 4rem;
    display: flex;
    align-items:center;
    padding-left: .5rem;
  }

  .mat-form-field {
    align-self: center;
  }
}

app-show-ranges-input {
  @extend .custom-mat-form;
  @extend .show-ranges-structure;
}

app-show-ranges-select {
  @extend .custom-mat-form;
  @extend .show-ranges-structure;
}

app-custom-mat-select {
  @extend .custom-mat-form;
  .select {
    display: flex;
    flex: 1;
    width: $custom-mat-width;
    padding-left: 2rem;
  }
}

app-custom-mat-multi-select {
  @extend .custom-mat-form;
  .select {
    display: flex;
    flex: 1;
    width: $custom-mat-width;
    padding-left: 2rem;
  }
}

.custom-product-maintenance-date-picker {
  @extend .custom-mat-form;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  & .mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: white;
  }
}

app-custom-mat-datepicker {
  @extend .custom-mat-form;
  .datepicker {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    width: $custom-mat-width;
    padding-left: 2rem;
  }
}

app-custom-mat-datepicker-list {
  @extend .custom-mat-form;
  .datepicker {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    width: $custom-mat-width;
    padding-left: 2rem;
  }

  .field-name {
    flex: none;
    padding-right: .4rem;
  }
}

app-custom-mat-datetimepicker {
  @extend .custom-mat-form;
  .datetimepicker {
    display: flex;
    flex: 1;
    width: $custom-mat-width;
    padding-left: 2rem;

    .mat-form-field-flex {
      width: 11rem;
    }
  }
}

app-underliers-subsection {
  @extend .custom-mat-form;

  width: 100%;
}

app-custom-mat-radio-buttons {
  .radio-buttons {
    display: flex;
    flex: 1;
    width: $custom-mat-width;
    padding-left: 2rem;
  }

  .field-name {
    flex: 1;
    align-self: center;
  }

  .radio-button-group {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .radio-button-div {
    display: flex;
    flex-direction: column;
    width: fit-content;
  }

  .mat-radio-button {
    margin-right: 0;
  }
}

app-static-field {
  .static-field {
    display: flex;
    width: $custom-mat-width;
    padding-left: 2rem;
  }

  .field-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }

  .field-value {
    width: 8rem;
    display: flex;
    justify-content: center;
  }
}

app-product-entry-subsection {
  app-custom-mat-input {
    height: 3rem;
  }
  app-custom-mat-select {
    height: 3rem;
  }
  app-custom-mat-multi-select {
    height: 3rem;
  }
  app-custom-mat-radio-buttons {
    min-height: 3rem;
  }
  app-custom-mat-datepicker {
    min-height: 3rem;
    height: auto;
  }
  app-custom-mat-datepicker-list {
    min-height: 3rem;
    height: auto;
  }
  app-custom-mat-datepicker-list {
    min-height: 3rem;
    height: auto;
  }
  app-custom-mat-datetimepicker {
    height: 3rem;
  }
  app-static-field {
    height: 3rem;
  }
}

app-mini-dynamic-container {
  .mini-container {
    width: $custom-mat-width;
    padding-left: 2rem;
  }

  app-custom-mat-input {
    .input {
      padding-left: 0;
    }
  }

  app-custom-mat-select {
    .select {
      padding-left: 0;
    }
  }

  app-custom-mat-multi-select {
    .select {
      padding-left: 0;
    }
  }

  app-custom-mat-datepicker {
    .datepicker {
      padding-left: 0;
    }
  }

  app-custom-mat-datepicker-list {
    .datepicker {
      padding-left: 0;
    }
  }

  app-custom-mat-datetimepicker {
    .datetimepicker {
      padding-left: 0;
    }
  }

  app-show-ranges-input {
    .show-ranges-container {
      padding-bottom: 0;
      padding-left: 0;
    }
  }

  app-custom-mat-radio-buttons {
    .radio-buttons {
      padding-left: 0;
    }
  }

  .leverage-factors {
    width: ($custom-mat-width * 2) + 2;
  }
  .reoffer-rates {
    width: ($custom-mat-width * 2) + 2;
  }
  .debt-isin-list {
    width: $custom-mat-width;
  }
  .cms-reference-index {
    width: ($custom-mat-width * 2) + 2;
  }
  .float-rate-fixing-schedule {
    width: $custom-mat-width;
  }
}


app-leverage-factors {
  @extend .custom-mat-form;

  .factor {
    .mat-form-field-flex {
      width: 4rem;
    }
  }
}

app-multiple-row-section {
  @extend .custom-mat-form;
  
  .flex-80 {
    .mat-form-field-flex {
      width: 4rem;
    }
  }
}

app-reoffer-rates {
  @extend .custom-mat-form;
  @extend .show-ranges-structure;

  .show-ranges-container {
    padding: 0;
  }

  .mat-form-field {
    padding-left: 0;
  }

  .form-field {
    padding-left: .5rem;
  }

  .button-container {
    padding-left: 0;
  }

  .mat-form-field-subscript-wrapper {
    position: static;
  }
}

app-debt-isin-list {
  @extend .custom-mat-form;

  .weight {
    .mat-form-field-flex {
      width: 4rem;
    }
  }
}

app-cms-reference-index {
  @extend .custom-mat-form;
}

app-product-entry {
  .select {
    .mat-form-field-flex > .mat-form-field-infix {
      padding: 0.4em 0px !important;
    }
  }
}

app-product-entry-edit-tabs {
  input:-webkit-autofill {
    -webkit-box-shadow:0 0 0 50px #f4f6f8 inset;
    -webkit-text-fill-color: #333;
  }
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px #f4f6f8 inset;
    -webkit-text-fill-color: #333;
  }
}

app-ancillary-features {
  @extend .custom-mat-form;
  @extend .show-ranges-structure;

  .datepicker {
    .mat-form-field-flex {
      width: 10rem;
    }
  }

  .select {
    display: flex;
    flex: 1;
    width: $custom-mat-width - 1;
  }

  .input {
    display: flex;
    flex: 1;
    width: $custom-mat-width - 1;
    padding-left: 2rem;
  }

  .show-ranges-container {
    width: $custom-mat-width - 1;
  }
}

app-multi-select-search-filter .ng-select-theme-default .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    max-width: 13em;
    white-space:normal;
    overflow: hidden;
}

ng-dropdown-panel.search-select-filter {
  background-color: white;
  padding: 1em 0;
  outline: 1px solid #cdcdcd;
  border-radius: 5px;
  min-width: 230px !important;
  font-size: 12px;
  width: auto !important;

  & .ng-dropdown-panel-items .ng-option {
    padding: 4px 8px;
    display: flex;
    align-items: center;
  }
  & .ng-option-marked, .ng-option-selected {
    background-color: $info-grey;
    & input {
      accent-color: $luma-primary-v2;
    }
  }

  //& .ng-option-selected {
  //  background-color: $active-green;
  //
  //}

  & .ng-select-button-container {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-evenly;
  }
  & button.btn.btn-sm.btn-secondary {
    font-size: .9em;
  }
}

//.ng-select-theme-default .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
//  background-color: #fff;
//  color: rgba(0, 0, 0, 0.87);
//  padding: 8px 10px;
//}
//<style>
// .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
//   box-sizing: border-box;
//   cursor: pointer;
//   display: block;
// }
//<style>
// .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;











app-multi-select-search-filter {
  .search-select-label {
    padding-bottom: .6em;
    color: $text-grey;
  }

  .ng-select-theme-default {
    .ng-select {
      font-family: 'Montserrat';
      font-size: 1em;

      & .ng-clear {
        transform: scale(1.3);
      }

      &.ng-select-multiple .ng-select-container .ng-value-container {
        & .ng-placeholder {
          padding: 0;
          top: 10px;
          left: 10px;
        }

        & .ng-value {
          color: $text-grey;
          background-color: $pure-white;
          border: 1px solid $input-field-grey;
          border-radius: 8px;
          font-size: .9em;
          padding: .3em;

          & .ng-value-label {
            padding: 1px 5px 1px 8px;
          }

          & .ng-value-icon.left {
            font-size: 1em;
            border-right: 1px solid #b8dbff;
            border-right-color: $input-field-grey;
            margin-right: 4px;
            transform: scale(1.5);
            padding-right: 4px;
            padding-left: 4px;

            &:hover {
              background-color: $disabled-grey;
            }
          }
        }
      }
    }
  }
  & button.btn.btn-sm.btn-secondary {
    margin: 0 .5em;
  }
}

app-external-filter-toolpanel {
  width: 100%;
}
app-mark-to-market-config {
  .email-addresses {
    white-space: break-spaces;
    overflow: hidden;
    display: flex;
    align-items: center;

    &.ag-cell {
      line-height: 1.25rem;
    }
  }
}

app-date-section {
  @extend .custom-mat-form;

  .input {
    .mat-form-field-flex {
      width: 4rem;
    }
  }

  .datepicker {
    .mat-form-field-flex {
      width: 8rem;
    }
  }

  .warning {
    .mat-form-field-outline {
      color: orange;
    }
    .mat-form-field-outline-thick {
      color: orange;
    }
  }
}

app-unwind-modal {
  .input-notional {
    @extend .custom-mat-form;
  }
}

app-bulk-actions-on-orders-modal {

.price-fields-container {
  & .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 1em .7em .7em .7em;
    border-top: 0;
    width: 8em;
  }
  & .price.mat-form-field-appearance-outline .mat-form-field-infix {
    width: 2em;
  }
}
}

// luma-month-picker.component
.custom-dp-header {
  & .mat-calendar-period-button {
    pointer-events: none;
  }

  & .mat-calendar-arrow {
    display: none;
  }
}

app-login-form {
  .login-button-spinner {
    margin-top: 10px;
    &.mat-progress-spinner circle, &.mat-spinner circle {
      stroke: $cta-primary-blue;
    }
  }
}

app-annuity-compare {
  .product-compare-footer .chart-date-picker > app-luma-month-picker > .luma-monthly-date-picker {
    & > mat-form-field {
      width: 100%;
    }

    .luma-input-chart {
      @extend .luma-input-small-text;
      .mat-form-field-wrapper {
        padding-bottom: 1em;
      }

      .mat-form-field-subscript-wrapper {
        margin-top: 2px;
      }
    }
  }
}

.company-logo {
  width: auto;
  height: auto;
  max-width: 140px;
  max-height: 40px;
}

app-issuer-confirmation-creation {
  .ag-theme-material.structured-products-grid .ag-cell-wrapper {
    align-items: center;
    margin: 0;
    padding: .5rem 0;
    height: inherit;
  }
}

app-annuity-tabbed-chart {
  height: 100%;
}

app-split-button {
  height: 100%;
  width: 100%;
}

.luma-hr {
  border-top: 1px solid $input-field-grey;
  margin: 0;
}

// Do not adjust values here unless LifetimeAnalysisSummaryComponent.labelWidthOffset is updated
.annuity-exchange-summary-column-chart-label {
  font-size: 12px;
  @media screen and (max-width: 1450px) {
    font-size: 10px;
  }

  width: 10.5em;
  padding: 1.33em 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  position: relative;
  font-weight: 900;
  .scenario-header {
    position: absolute;
    top: -2em;
    text-align: center;
    width: 100%;
    & > app-icon-tooltip {
      padding-left: 4px;
    }
  }

  .net-benefit {
    text-align: center;
    color: $graphing-blue-3;
    .fa-sack-dollar {
      font-size: 1.2em;
    }
    &-value {
      font-size: 1.667em;
      text-align: center;
    }
  }
}

app-broker-dealer-administration app-underline-tabs .table-controls{
  justify-content: center;
}

app-product-details-modal,
app-product-details-modal-core,
app-product-details-modal-metrics,
app-product-details-modal-payout-chart,
app-product-details-modal-underlier-chart {
  .card {
    border-radius: 4px;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    min-height: 320px;
    background-color: white;
    margin: $spacing-s;
    height: calc(100% - #{$spacing-s} * 2);

    &-header {
      font-family: $font-family;
      font-size: 14px;
      font-weight: 900;
      color: $text-grey;
      background-color: $porcelain;
      display: flex;
      flex-direction: row;
      padding: $spacing-s $spacing-xl;

      span {
        justify-self: flex-start;
        align-content: center;
      }

      .underlier-header {
        display: flex;
      }

      &-download {
        flex: 1;
        display: flex;
        justify-content: flex-end;

        button {
          border: none;
          background-color: unset;
          color: $luma-link;
          font-family: $font-family;
          font-size: 12px;
          font-weight: 500;
          cursor: pointer;
        }

        fa-icon {
          font-size: 14px;
          padding-right: $spacing-xs;
        }
      }

      &-legend {
        display: flex;
        justify-content: flex-start;
        padding-left: $spacing-xl;
        font-weight: 500;

        .legend-icon {
          position: relative;
          width: 24px;
          height: 11px;
          margin: auto $spacing-s auto 0;

          &-line {
            position: absolute;
            top: 6px;
            width: 16px;
            height: 1px;
            background-color: $luma-brand-bright-green;
          }
          &-circle {
            position: absolute;
            top: 3px;
            left: 4px;
            width: 8px;
            height: 8px;
            background-color: $luma-brand-bright-green;
            border-radius: 10px;
          }
        }
      }
    }

    &-content {
      flex: 1;
      position: relative;
      .metric-container {
        height: 100%;
      }
    }
  }
.center-text-on-page {
  width: calc( 100% - 2em);
  height: calc(50vh - 2em);
  margin: 1em;
  text-align: center;
  background-color:white;
  border: 1px solid $disabled-text-grey;
}

  .page-spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

app-product-details-modal app-split-button .container {
  height: 42px;

  .main {
    border-radius: 8px 0 0 8px;
  }

  .drop {
    border-radius: 0 8px 8px 0;
  }
}

.reactive-animated-dialog-width {
  width: min(100%, 1000px);
  height: 100vh;
}


// ng deep no More
app-portfolio-calendar, app-annuities-calendar {
  .mat-calendar-arrow {
    display: none !important;
  }

  tbody tr:not([role]) {
    display: none;
  }

  .mat-calendar-body-label {
    visibility: hidden;
  }

  .mat-calendar-body-cell {
    border: 1px solid lightgray;
  }

  .event-date {
    background-color: $luma-link;
  }

  .selected-date-range {
    background-color: $luma-secondary-button;
  }

  .non-event-date.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
    color:black;
  }

  .event-date.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
    color: white;
  }

  div.mat-calendar-controls {
    display: none;
  }

  .event-type-filter, .issuer-filter {
    display: inline-block;
    width: calc(50% - 20px);

    .issuer-label {
      white-space: normal;
    }
  }
}

.top-nav {
  app-compare-card {
    width: 100%;
  }
}

.add-button-row {
  width: 100%;
  padding-top: .5rem;
  display: flex;
  justify-content: center;

  .plus-icon {
    padding-right: .5rem;
    color: $luma-success-color;
  }
}

.center-element {
    display: flex;
    justify-content: center;
    align-items: center;
}

#toast-container {
  z-index: 9999999;
}

.toast-position {
    position: fixed;
    bottom: 100px;
    right: 20px;
}

.disable-input {
  pointer-events: none;
  opacity: 0.4;
}

